import {
  Button,
  Col,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Tag,
  Typography,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import WalletHeader from "../component/walletHeader";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { updateInitial } from "../store/user";
import axios from "axios";
import { defaultTheme } from "antd/es/theme/context";

const { Text } = Typography;

export default function ThreeDSelectList() {
  const {
    window_height,
    threeDselect,
    select2Damount,
    user_id,
    agent_code,
    main,
    get3DList,
    promotion,
    select_3d_agent,
    use_wallet,
  } = useSelector((state) => state.initial);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const [updateAmount, setUPdateAmount] = useState(0);
  const [newKey, setNewKey] = useState([]);
  const [loading, setLoading] = useState(false);

  async function bitHandler() {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_API}/v1/bet/threed_bet/${user_id}/${agent_code}`,
        {
          select_agent: select_3d_agent[0].id,
          wallet: use_wallet === "promotion_wallet" ? "PROMOTION" : "MAIN",
          user_id,
          threed: threeDselect.map((value) => ({
            amount: value.amount,
            key: value.key,
          })),
        }
      )
      .then(({ data }) => {
        dispatch(
          updateInitial({
            threeDselect: [],
            get3DList: [],
            select_3d_agent: false,
            select2Damount: 0,
          })
        );
        setLoading(false);
        navigate("/");
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  }

  function getMaxAmount() {
    let totalAmount = 0;
    let wallet_amount = use_wallet === "promotion_wallet" ? promotion : main;

    if (threeDselect.length > 0) {
      for (let i of threeDselect) {
        totalAmount = totalAmount + i.amount;
      }
    }

    let target = threeDselect.find((vlaue) => vlaue.key === update);

    let maximumAmount = target?.max_bit || 0;
    let avilableAmount = wallet_amount - totalAmount;

    return avilableAmount <= maximumAmount ? avilableAmount : maximumAmount;
  }

  function errorMessage(segment) {
    let wallet_amount = use_wallet === "promotion_wallet" ? promotion : main;
    let totalAmount = 0;

    if (threeDselect.length > 0) {
      for (let i of threeDselect) {
        totalAmount = totalAmount + i.amount;
      }
    }

    switch (true) {
      case segment.amount > wallet_amount || totalAmount > wallet_amount:
        return "ထိုးငွေ မလောက်ပါ";
        break;
      case segment.amount > segment.max_bit ||
        segment.amount > segment.avilableAmount:
        return `ထိုးငွေပမာဏ ကျော်လွန် ( အများဆုံး (${segment.avilableAmount}))`;
        break;
      case segment.amount < segment.min_bit:
        return `အနဲဆုံးထိုးငွေ ${segment.min_bit}`;
        break;
      default:
        return "";
        break;
    }
  }

  return (
    <div style={{ height: window_height, width: "100vw", overflow: "hidden" }}>
      <Layout>
        <Layout.Content>
          <WalletHeader />
          <div
            style={{
              height: `${window_height - 60}px`,
              overflowY: "auto",
              overflowX: "hidden",
              background: "#1d262fff",
              scrollbarWidth: "none",
            }}
          >
            {threeDselect.length === 0 ? (
              <div style={{ padding: "16px" }}>
                <Skeleton active={true} />
              </div>
            ) : (
              <Spin spinning={loading}>
                <Row
                  gutter={[24, 24]}
                  style={{
                    padding: "16px",
                  }}
                >
                  <Col span={24}>
                    <Row justify={"space-between"} align={"middle"}>
                      <Col>
                        <Text strong style={{ color: "#34AF83" }}>
                          ထိုးဂဏန်း တိုးမည်
                        </Text>
                      </Col>
                      <Col>
                        <Text
                          strong
                          style={{ fontWeight: "900", color: "#34AF83" }}
                        >
                          - - -
                        </Text>
                      </Col>
                      <Col span={12}>
                        <Select
                          size="large"
                          onChange={(e) => setNewKey(e)}
                          style={{ width: "100%" }}
                          showSearch
                          mode="multiple"
                          options={get3DList
                            .filter(
                              (value) =>
                                value.avilableAmount > 0 &&
                                !threeDselect.find(
                                  (three) => three.key === value.key
                                )
                            )
                            .map((value) => ({
                              value: value.key,
                              label:
                                value.key < 10
                                  ? `00${value.key}`
                                  : value.key < 100
                                  ? `0${value.key}`
                                  : `${value.key}`,
                            }))}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Space.Compact style={{ width: "100%" }}>
                      <Input
                        size="large"
                        style={{
                          borderTopLeftRadius: "12px",
                          borderBottomLeftRadius: "12px",
                          border: "none",
                          backgroundColor: "#2A3642",
                        }}
                        value={select2Damount}
                        placeholder={`အနဲဆုံးထိုးငွေ ${get3DList[0].min_bit}`}
                        onChange={(e) => {
                          dispatch(
                            updateInitial({ select2Damount: +e.target.value })
                          );
                        }}
                      />
                      <Button
                        size="large"
                        onClick={() => {
                          if (select2Damount < get3DList[0].min_bit) {
                            message.error(
                              `အနဲဆုံးထိုးငွေ${get3DList[0].min_bit}`
                            );
                            return;
                          }
                          let wallet_amount =
                            use_wallet === "promotion" ? promotion : main;

                          let currentTotal = 0;

                          for (let i of threeDselect) {
                            currentTotal = currentTotal + i.amount;
                          }

                          let newTotal = select2Damount * newKey.length;

                          if (wallet_amount < currentTotal + newTotal) {
                            message.warning("ငွေမလောက်ပါ");
                            return;
                          }

                          let newsThreeds = [];

                          for (let i of newKey) {
                            newsThreeds.push({
                              ...get3DList[i],
                              amount: select2Damount,
                            });
                          }

                          if (newsThreeds.length > 0) {
                            dispatch(
                              updateInitial({
                                threeDselect: [...threeDselect, ...newsThreeds],
                              })
                            );
                          }
                        }}
                        style={{
                          border: "none",
                          borderTopRightRadius: "12px",
                          borderBottomRightRadius: "12px",
                          boxShadow: "none",
                          fontWeight: "600",
                          color: "#34AF83",
                          paddingRight: "24px",
                          backgroundColor: "#181F25",
                        }}
                      >
                        ထိုးမည်
                      </Button>
                    </Space.Compact>
                  </Col>
                  <Col span={24}>
                    <Row justify={"center"}>
                      {threeDselect.length > 0 && (
                        <>
                          {threeDselect.map((timeNumber, index) => (
                            <Col
                              style={{
                                background: index % 2 ? "#2A3642" : "#1D262F",
                                padding: "8px 12px",
                                borderRadius: "8px",
                              }}
                              span={24}
                              key={timeNumber.number}
                            >
                              {index === 0 && (
                                <Row
                                  style={{ marginBottom: "10px" }}
                                  justify={"space-between"}
                                  align={"middle"}
                                >
                                  <Col span={4}>
                                    <Text strong>စဥ်</Text>
                                  </Col>
                                  <Col span={6}>
                                    <Row justify={"center"}>
                                      <Col>
                                        <Text>ဂဏန်း</Text>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={6}>
                                    <Row justify={"center"}>
                                      <Col>
                                        <Text>ပမာဏ</Text>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={4}>
                                    <Row justify={"end"}>
                                      <Col>
                                        <Text>ပြင်/ဖျက်</Text>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              )}
                              <Row justify={"space-between"} align={"middle"}>
                                <Col span={4}>
                                  <Text
                                    style={{
                                      fontSize: "1.2rem",
                                    }}
                                    strong
                                  >
                                    {index + 1}
                                  </Text>
                                </Col>
                                <Col span={6}>
                                  <Row justify={"center"}>
                                    <Col>
                                      <Text
                                        style={{
                                          fontSize: "1.4rem",
                                          color: "#34AF83",
                                        }}
                                        strong
                                      >
                                        {timeNumber.number}
                                      </Text>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={6}>
                                  <Row justify={"center"}>
                                    <Col>
                                      <Tag
                                        bordered={false}
                                        style={{
                                          width: "80px",
                                          borderRadius: "5px",
                                          textAlign: "center",
                                          letterSpacing: "1px",
                                          fontWeight: "700",
                                          background: "#181F25",
                                        }}
                                      >
                                        {timeNumber.amount}
                                      </Tag>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={4}>
                                  <Row gutter={12} justify={"space-around"}>
                                    <Col
                                      onClick={() => setUpdate(timeNumber.key)}
                                      style={{
                                        background: "#6076C0",
                                        padding: "2px 4px",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <img src="/images/edit.png" alt="edit" />
                                    </Col>
                                    <Col
                                      onClick={() =>
                                        dispatch(
                                          updateInitial({
                                            threeDselect: threeDselect.filter(
                                              (value) =>
                                                value.key !== timeNumber.key
                                            ),
                                          })
                                        )
                                      }
                                      style={{
                                        background: "#F91C1C",
                                        padding: "2px 4px",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <img
                                        src="/images/deleteIcon.png"
                                        alt="edit"
                                      />
                                    </Col>
                                  </Row>
                                </Col>

                                <Col span={24}>
                                  <Text
                                    type="danger"
                                    style={{ fontSize: "0.6rem" }}
                                  >
                                    {errorMessage(timeNumber)}
                                  </Text>
                                </Col>
                              </Row>
                            </Col>
                          ))}
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Spin spinning={loading}>
                      <Row justify={"space-between"} gutter={24}>
                        <Col span={12}>
                          <div
                            style={{
                              backgroundColor: "#434F5D",
                              fontWeight: "800",
                              fontSize: "1rem",
                              padding: "8px 12px",
                              textAlign: "center",
                              borderRadius: "20px",
                              width: "100%",
                            }}
                            onClick={() => navigate("/twodselect")}
                          >
                            နောက်သို့
                          </div>
                        </Col>
                        <Col span={12}>
                          <div
                            style={{
                              backgroundColor: "#34AF83",
                              fontWeight: "800",
                              fontSize: "1rem",
                              padding: "8px 12px",
                              textAlign: "center",
                              borderRadius: "20px",
                              width: "100%",
                            }}
                            onClick={() => bitHandler()}
                          >
                            ထိုးမည်
                          </div>
                        </Col>
                      </Row>
                    </Spin>
                  </Col>
                  <Col span={24} style={{height:'100vh'}}></Col>
                </Row>
              </Spin>
            )}
          </div>
        </Layout.Content>
      </Layout>
      <Modal
        name="edit"
        onCancel={() => {
          setUPdateAmount(false);
          setUpdate(false);
        }}
        open={update || update === 0}
        closable={false}
        footer={false}
        destroyOnClose
      >
        <Row gutter={[16, 24]}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Text style={{ fontSize: "1.2rem", fontWeight: "600" }}>
              ထိုးငွေပမာဏပြင်ရန်
            </Text>
          </Col>
          <Col span={24}>
            <InputNumber
              size="large"
              style={{ width: "100%", marginBottom: "9px" }}
              min={get3DList.length > 0 ? get3DList[0].min_bit : 100}
              max={getMaxAmount()}
              value={updateAmount}
              onChange={(e) => setUPdateAmount(e)}
            />
            <Text strong>
              အနည်းဆုံးထိုးငွေ{" "}
              {get3DList.length > 0 ? get3DList[0].min_bit : 100}
              အများဆုံးထိုးငွေ {getMaxAmount()}
            </Text>
          </Col>
          <Col span={24}>
            <div
              style={{
                margin: "10px",
                background: "#34AF83",
                padding: "12px",
                textAlign: "center",
                borderRadius: "16px",
              }}
              onClick={() => {
                if (update || (update === 0 && updateAmount > 0)) {
                  const copy2d = threeDselect.map((value) => value);
                  const target = copy2d[update];
                  copy2d[update] = {
                    ...copy2d[update],
                    amount: updateAmount,
                  };

                  console.log(copy2d, target);

                  dispatch(
                    updateInitial({
                      threeDselect: threeDselect.map((value) =>
                        value.key === update
                          ? { ...value, amount: updateAmount }
                          : value
                      ),
                    })
                  );

                  setUPdateAmount(false);
                  setUpdate(false);
                }
              }}
            >
              <Text strong style={{ fontSize: "1.2rem", fontWeight: "700" }}>
                အတည်ပြု
              </Text>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
