import { Carousel, ConfigProvider } from "antd";

export default function AdCarousel() {
  return (
    <div style={{ borderRadius: "10px", overflow: "hidden" }}>
      <ConfigProvider
        theme={{
          components: {
            Carousel: {
              dotHeight: 10,
              dotWidth: 10,
              dotActiveWidth: 10,
              colorBgContainer:'#fff'
            },
          },
        }}
      >
        <Carousel
          pauseOnFocus
          autoplay
          autoplaySpeed={3000}
        >
          <div>
            <img
              style={{
                width:'100%',
                height:'50vw',
                objectFit: "cover",
              }}
              src="/carousel/2d banner.jpg"
              alt="ad"
            />
          </div>
          <div>
            <img
              style={{
                width:'100%',
                height:'50vw',
                objectFit: "cover",
              }}
              src="/carousel/3d banner.jpg"
              alt="ad"
            />
          </div>
        </Carousel>
      </ConfigProvider>
    </div>
  );
}
