import {
  Button,
  Col,
  Input,
  Layout,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import WalletHeader from "../component/walletHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { updateInitial } from "../store/user";

const { Text } = Typography;

export default function ThreeDSelect() {
  const {
    window_height,
    get3DList,
    threeDselect,
    select_3d_agent,
    promotion,
    main,
    use_wallet,
  } = useSelector((state) => state.initial);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [minAmount, setMinAmount] = useState(0);

  console.log(threeDselect);

  const [inputAmount, setInputAmount] = useState(null);
  const [mult, setMult] = useState([
    { key: 0, name: "000-099", start: 0, end: 100, select: true },
    { key: 1, name: "100-199", start: 100, end: 200, select: false },
    { key: 2, name: "200-299", start: 200, end: 300, select: false },
    { key: 3, name: "300-399", start: 300, end: 400, select: false },
    { key: 4, name: "400-499", start: 400, end: 500, select: false },
    { key: 5, name: "500-599", start: 500, end: 600, select: false },
    { key: 6, name: "600-699", start: 600, end: 700, select: false },
    { key: 7, name: "700-799", start: 700, end: 800, select: false },
    { key: 8, name: "800-899", start: 800, end: 900, select: false },
    { key: 9, name: "900-999", start: 900, end: 1000, select: false },
  ]);

  useEffect(() => {
    let raw = [];
    if (select_3d_agent && select_3d_agent.length > 0) {
      raw = JSON.parse(select_3d_agent[0].threed).map((three) => ({
        key: three.key,
        number:
          three.key < 10
            ? `00${three.key}`
            : three.key < 100
            ? `0${three.key}`
            : `${three.key}`,
        min_bit: three.min_bit,
        max_bit: three.max_bit,
        max: three.max,
        current: three.current,
        avilableAmount: three.max - three.current,
        percent: (three.current / three.max) * 100,
        amount: 0,
        is_select: false,
      }));

      console.log(raw[4]);

      setMinAmount(raw[0].min_bit);

      dispatch(updateInitial({ get3DList: raw }));
    }
  }, []);

  function pregressColorHandler(percent) {
    switch (true) {
      case percent > 50 && percent < 75:
        return `#F9A21C ${percent}%`;
      case percent > 75:
        return `#F91C1C ${percent}%`;
      default:
        return `#34AF83 ${percent}%`;
    }
  }

  function rHandler() {
    let selected = [];

    for (let three of threeDselect) {
      const selectKey = three.number.split("");
      let Rkeys = [
        ...new Set([
          +`${selectKey[0]}${selectKey[1]}${selectKey[2]}`,
          +`${selectKey[0]}${selectKey[2]}${selectKey[1]}`,
          +`${selectKey[1]}${selectKey[0]}${selectKey[2]}`,
          +`${selectKey[1]}${selectKey[2]}${selectKey[0]}`,
          +`${selectKey[2]}${selectKey[0]}${selectKey[1]}`,
          +`${selectKey[2]}${selectKey[1]}${selectKey[0]}`,
        ]),
      ];

      for (let i of Rkeys) {
        const target = get3DList[i];

        if (!threeDselect.find((value) => value.key === i)) {
          selected.push(target);
        }
      }

      if (selected.length > 0) {
        dispatch(
          updateInitial({ threeDselect: [...threeDselect, ...selected] })
        );
      }
    }
  }

  function selectHandler() {
    const target = mult.find((value) => value.select);

    return get3DList.length > 0 ? (
      get3DList.slice(target.start, target.end).map((value, index) => (
        <Col flex={"20%"} key={`3d_${value.key}`}>
          <div
            onClick={() => {
              const check = threeDselect.find((sele) => sele.key === value.key);

              if (!check) {
                dispatch(
                  updateInitial({ threeDselect: [...threeDselect, value] })
                );
              } else {
                dispatch(
                  updateInitial({
                    threeDselect: [
                      ...threeDselect.filter(
                        (three) => three.key !== value.key
                      ),
                    ],
                  })
                );
              }
            }}
            style={{
              opacity: value.avilableAmount < 100 ? "40%" : "100%",
              borderRadius: "12px",
              background: !!threeDselect.find((sele) => sele.key === value.key)
                ? "#fff"
                : "#181F25",
              padding: "12px 6px",
            }}
          >
            <Row justify={"center"} gutter={[0, 4]}>
              <Col>
                <Text
                  style={{
                    color: !!threeDselect.find((sele) => sele.key === value.key)
                      ? "#000"
                      : "#fff",
                  }}
                  strong
                >
                  {value.number}
                </Text>
              </Col>
              <Col span={24}>
                <div
                  style={{
                    height: "4px",
                    width: "100%",
                    borderRadius: "2px",
                    background:
                      value.percent === 100
                        ? "#F91C1C"
                        : `linear-gradient(to right,${pregressColorHandler(
                            value.percent
                          )},#434F5D ${value.percent}%)`,
                  }}
                ></div>
              </Col>
            </Row>
          </div>
        </Col>
      ))
    ) : (
      <div></div>
    );
  }

  return (
    <div style={{ height: window_height, width: "100vw", overflow: "hidden" }}>
      <Layout>
        <Layout.Content>
          <WalletHeader />
          <div
            style={{
              height: `${window_height - 60}px`,
              overflowY: "auto",
              overflowX: "hidden",
              background: "#1d262fff",
              scrollbarWidth: "none",
            }}
          >
            <Row
              gutter={[24, 24]}
              style={{
                padding: "16px",
              }}
            >
              <Col span={24}>
                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    size="large"
                    style={{
                      borderTopLeftRadius: "12px",
                      borderBottomLeftRadius: "12px",
                      border: "none",
                      backgroundColor: "#2A3642",
                    }}
                    value={inputAmount}
                    placeholder={`အနဲဆုံးထိုးငွေ ${minAmount}`}
                    onChange={(e) => {
                      setInputAmount(+e.target.value);
                    }}
                  />
                  <Button
                    size="large"
                    onClick={() => {
                      let wallet_amount =
                        use_wallet === "promotion" ? promotion : main;

                      let total = inputAmount * threeDselect.length;

                      if (total > wallet_amount) {
                        message.error(
                          `ထိုးငွေ ${inputAmount} အတွက် ‌ငွေမလောက်ပါ`
                        );
                        return;
                      }

                      if (inputAmount < minAmount) {
                        message.warning(`အနဲဆုံး ထိုးငွေ ${minAmount}`);
                        return;
                      }

                      dispatch(
                        updateInitial({
                          threeDselect: threeDselect.map((value) => ({
                            ...value,
                            amount: inputAmount,
                          })),
                        })
                      );
                      navigate("/threeDselectList");
                    }}
                    style={{
                      border: "none",
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                      boxShadow: "none",
                      fontWeight: "600",
                      color: "#34AF83",
                      paddingRight: "24px",
                      backgroundColor: "#181F25",
                    }}
                  >
                    ထိုးမည်
                  </Button>
                </Space.Compact>
              </Col>
              <Col span={24}>
                <Row align={"middle"} justify={"space-between"}>
                  <Col>
                    <Text
                      style={{
                        color: "#34AF83",
                        fontSize: "1.2rem",
                        fontWeight: "800",
                      }}
                    >
                      ထိုးဂဏန်း
                    </Text>
                    <Text style={{ fontSize: "1.2rem", fontWeight: "800" }}>
                      ရွေးရန်
                    </Text>
                  </Col>
                  <Col>
                    <Row gutter={[12, 12]}>
                      <Col
                        onClick={() => {
                          rHandler();
                        }}
                      >
                        <div
                          style={{
                            padding: "12px 22px",
                            borderRadius: "10px",
                            background: "#181F25",
                          }}
                        >
                          <Text strong style={{ fontSize: "1.2rem" }}>
                            R
                          </Text>
                        </div>
                      </Col>
                      <Col
                        onClick={() =>
                          dispatch(updateInitial({ threeDselect: [] }))
                        }
                      >
                        <div
                          style={{
                            padding: "12px 22px",
                            borderRadius: "10px",
                            background: "#181F25",
                          }}
                        >
                          <Text strong style={{ fontSize: "1.2rem" }}>
                            Clear
                          </Text>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <div style={{ background: "#181F25", padding: "8px" }}>
                  <Row gutter={[8, 8]} style={{ transition: "2s easein" }}>
                    {mult.map((key) => (
                      <Col
                        key={`selector_${key.key}`}
                        flex="20%"
                        onClick={() => {
                          setMult(
                            mult.map((value) =>
                              value.key === key.key
                                ? { ...value, select: true }
                                : { ...value, select: false }
                            )
                          );
                        }}
                      >
                        <div
                          style={{
                            padding: "20px 0px",
                            borderRadius: "12px",
                            background: key.select ? "#34AF83" : "#2A3642",
                            textAlign: "center",
                          }}
                        >
                          <Text
                            strong
                            style={{ fontSize: "0.8rem", fontWeight: "700" }}
                          >
                            {key.name}
                          </Text>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
              <Col span={24}>
                <Row gutter={[16, 16]}>{selectHandler()}</Row>
              </Col>
              <Col span={24} style={{ height: "100vh" }}></Col>
            </Row>
          </div>
        </Layout.Content>
      </Layout>
    </div>
  );
}
