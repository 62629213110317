import {
  Button,
  Col,
  Input,
  InputNumber,
  Layout,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Tag,
  Typography,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import WalletHeader from "../component/walletHeader";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { updateInitial } from "../store/user";
import axios from "axios";

const { Text } = Typography;

export default function TwoDSelectedList() {
  const {
    window_height,
    select2D,
    select2Damount,
    user_id,
    agent_code,
    main,
    promotion,
    select_agent,
    use_wallet,
  } = useSelector((state) => state.initial);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const [updateAmount, setUPdateAmount] = useState(0);
  const [newKey, setNewKey] = useState(false);
  const [loading, setLoading] = useState(false);

  async function bitHandler() {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_API}/v1/bet/twod_bet/${user_id}/${agent_code}`,
        {
          select_agent: select_agent[0].id,
          wallet: use_wallet === "promotion_wallet" ? "PROMOTION" : "MAIN",
          user_id,
          twods: select2D
            .filter((value) => value.is_select)
            .map((value) => ({ amount: value.amount, key: value.key })),
        }
      )
      .then(({ data }) => {
        dispatch(
          updateInitial({
            select_section: "",
            select_agent: false,
            select2D: [],
            select2Damount: 0,
          })
        );
        setLoading(false);
        navigate("/");
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  }

  function getMaxAmount() {
    let totalAmount = 0;
    let wallet_amount = use_wallet === "promotion_wallet" ? promotion : main;

    if (select2D.length > 0) {
      for (let i of select2D) {
        totalAmount = totalAmount + i.amount;
      }
    }

    const target = select2D.find((value) => value.key === update);

    let maximumAmount = target?.max_bit || 0;
    let avilableAmount = wallet_amount - totalAmount;

    return avilableAmount <= maximumAmount ? avilableAmount : maximumAmount;
  }

  function errorMessage(segment) {
    let wallet_amount = use_wallet === "promotion_wallet" ? promotion : main;
    let totalAmount = 0;

    if (select2D.length > 0) {
      for (let i of select2D) {
        totalAmount = totalAmount + i.amount;
      }
    }

    switch (true) {
      case segment.amount > wallet_amount || totalAmount > wallet_amount:
        return "ထိုးငွေ မလောက်ပါ";
        break;
      case segment.amount > segment.max_bit ||
        segment.amount > segment.avilableAmount:
        return `ထိုးငွေပမာဏ ကျော်လွန် ( အများဆုံး (${segment.avilableAmount}))`;
        break;
      case segment.amount < segment.min_bit:
        return `အနဲဆုံးထိုးငွေ ${segment.min_bit}`;
        break;
      default:
        return "";
        break;
    }
  }

  return (
    <div style={{ height: window_height, width: "100vw", overflow: "hidden" }}>
      <Layout>
        <Layout.Content>
          <WalletHeader />
          <div
            style={{
              height: `${window_height - 60}px`,
              overflowY: "auto",
              overflowX: "hidden",
              background: "#1d262fff",
              scrollbarWidth: "none",
            }}
          >
            {select2D.length === 0 ? (
              <div style={{ padding: "16px" }}>
                <Skeleton active={true} />
              </div>
            ) : (
              <Spin spinning={loading}>
                <Row
                  gutter={[24, 24]}
                  style={{
                    padding: "16px",
                  }}
                >
                  <Col span={24}>
                    <Row justify={"space-between"} align={"middle"}>
                      <Col>
                        <Text strong style={{ color: "#34AF83" }}>
                          ထိုးဂဏန်း တိုးမည်
                        </Text>
                      </Col>
                      <Col>
                        <Text
                          strong
                          style={{ fontWeight: "900", color: "#34AF83" }}
                        >
                          - - - -
                        </Text>
                      </Col>
                      <Col span={8}>
                        <Select
                          size="large"
                          onChange={(e) => setNewKey(e)}
                          style={{ width: "100%" }}
                          showSearch
                          options={select2D
                            .filter(
                              (value) =>
                                value.avilableAmount > 0 && !value.is_select
                            )
                            .map((value) => ({
                              value: value.key,
                              label:
                                value.key < 10 ? `0${value.key}` : value.key,
                            }))}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Space.Compact style={{ width: "100%" }}>
                      <Input
                        size="large"
                        style={{
                          borderTopLeftRadius: "12px",
                          borderBottomLeftRadius: "12px",
                          border: "none",
                          backgroundColor: "#2A3642",
                        }}
                        value={select2Damount}
                        placeholder={`အနဲဆုံးထိုးငွေ ${select2D[0].min_bit}`}
                        onChange={(e) => {
                          dispatch(
                            updateInitial({ select2Damount: +e.target.value })
                          );
                        }}
                      />
                      <Button
                        size="large"
                        onClick={() => {
                          if (newKey || newKey === 0) {
                            if (select2Damount < select2D[0].min_bit) {
                              message.error(
                                `အနဲဆုံးထိုးငွေ ${select2D[0].min_bit}`
                              );
                              return;
                            }

                            const copy2d = select2D.map((value) => value);
                            let totalAmount = 0;

                            for (let i of select2D) {
                              totalAmount = totalAmount + i.amount;
                            }

                            let wallet_amount =
                              use_wallet === "promotion" ? promotion : main;

                            if (
                              copy2d[newKey].avilableAmount >= select2Damount &&
                              totalAmount <= wallet_amount
                            ) {
                              copy2d[newKey] = {
                                ...copy2d[newKey],
                                amount: select2Damount,
                                is_select: true,
                              };
                              dispatch(
                                updateInitial({ select2D: [...copy2d] })
                              );
                            }
                          }
                        }}
                        style={{
                          border: "none",
                          borderTopRightRadius: "12px",
                          borderBottomRightRadius: "12px",
                          boxShadow: "none",
                          fontWeight: "600",
                          color: "#34AF83",
                          paddingRight: "24px",
                          backgroundColor: "#181F25",
                        }}
                      >
                        ထိုးမည်
                      </Button>
                    </Space.Compact>
                  </Col>
                  <Col span={24}>
                    <Row justify={"center"}>
                      {select2D.length > 0 && (
                        <>
                          {select2D
                            .filter((value) => value.is_select)
                            .map((timeNumber, index) => (
                              <Col
                                style={{
                                  background: index % 2 ? "#2A3642" : "#1D262F",
                                  padding: "8px 12px",
                                  borderRadius: "8px",
                                }}
                                span={24}
                                key={timeNumber.index}
                              >
                                {index === 0 && (
                                  <Row
                                    style={{ marginBottom: "10px" }}
                                    justify={"space-between"}
                                    align={"middle"}
                                  >
                                    <Col span={4}>
                                      <Text strong>စဥ်</Text>
                                    </Col>
                                    <Col strong span={6}>
                                      <Row justify={"center"}>
                                        <Col>
                                          <Text>ဂဏန်း</Text>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col strong span={6}>
                                      <Row justify={"center"}>
                                        <Col>
                                          <Text>ပမာဏ</Text>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col strong span={4}>
                                      <Row justify={"end"}>
                                        <Col>
                                          <Text>ပြင်/ဖျက်</Text>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                )}
                                <Row justify={"space-between"} align={"middle"}>
                                  <Col span={4}>
                                    <Text
                                      style={{
                                        fontSize: "1.2rem",
                                      }}
                                      strong
                                    >
                                      {index + 1}
                                    </Text>
                                  </Col>
                                  <Col span={6}>
                                    <Row justify={"center"}>
                                      <Col>
                                        <Text
                                          style={{
                                            fontSize: "1.4rem",
                                            color: "#34AF83",
                                          }}
                                          strong
                                        >
                                          {timeNumber.number}
                                        </Text>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={6}>
                                    <Row justify={"center"}>
                                      <Col>
                                        <Tag
                                          bordered={false}
                                          style={{
                                            width: "80px",
                                            borderRadius: "5px",
                                            textAlign: "center",
                                            letterSpacing: "1px",
                                            fontWeight: "700",
                                            background: "#181F25",
                                          }}
                                        >
                                          {timeNumber.amount}
                                        </Tag>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={4}>
                                    <Row gutter={12} justify={"space-around"}>
                                      <Col
                                        onClick={() =>
                                          setUpdate(timeNumber.key)
                                        }
                                        style={{
                                          background: "#6076C0",
                                          padding: "2px 4px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        <img
                                          src="/images/edit.png"
                                          alt="edit"
                                        />
                                      </Col>
                                      <Col
                                        onClick={() => {
                                          let copy2d = select2D.map(
                                            (value) => value
                                          );
                                          copy2d[timeNumber.key] = {
                                            ...copy2d[timeNumber.key],
                                            is_select: false,
                                            amount: 0,
                                          };

                                          dispatch(
                                            updateInitial({
                                              select2D: [...copy2d],
                                            })
                                          );
                                        }}
                                        style={{
                                          background: "#F91C1C",
                                          padding: "2px 4px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        <img
                                          src="/images/deleteIcon.png"
                                          alt="edit"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={24}>
                                    <Text
                                      type="danger"
                                      style={{ fontSize: "0.6rem" }}
                                    >
                                      {errorMessage(timeNumber)}
                                    </Text>
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Spin spinning={loading}>
                      <Row justify={"space-between"} gutter={24}>
                        <Col span={12}>
                          <div
                            style={{
                              backgroundColor: "#434F5D",
                              fontWeight: "800",
                              fontSize: "1rem",
                              padding: "8px 12px",
                              textAlign: "center",
                              borderRadius: "20px",
                              width: "100%",
                            }}
                            onClick={() => navigate("/twodselect")}
                          >
                            နောက်သို့
                          </div>
                        </Col>
                        <Col span={12}>
                          <div
                            style={{
                              backgroundColor: "#34AF83",
                              fontWeight: "800",
                              fontSize: "1rem",
                              padding: "8px 12px",
                              textAlign: "center",
                              borderRadius: "20px",
                              width: "100%",
                            }}
                            onClick={() => bitHandler()}
                          >
                            ထိုးမည်
                          </div>
                        </Col>
                      </Row>
                    </Spin>
                  </Col>
                  <Col span={24} style={{height:'100vh'}}></Col>
                </Row>
              </Spin>
            )}
          </div>
        </Layout.Content>
      </Layout>
      <Modal
        name="edit"
        onCancel={() => {
          setUPdateAmount(false);
          setUpdate(false);
        }}
        open={update || update === 0}
        closable={false}
        footer={false}
        destroyOnClose
      >
        <Row gutter={[16, 24]}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Text style={{ fontSize: "1.2rem", fontWeight: "600" }}>
              ထိုးငွေပမာဏပြင်ရန်
            </Text>
          </Col>
          <Col span={24}>
            <InputNumber
              size="large"
              style={{ width: "100%", marginBottom: "9px" }}
              min={select2D.length > 0 ? select2D[0].min_bit : 100}
              max={getMaxAmount()}
              value={updateAmount}
              onChange={(e) => setUPdateAmount(e)}
            />
            <Text strong>
              အနည်းဆုံးထိုးငွေ {select2D.length > 0 ? select2D[0].min_bit : 100}
              အများဆုံးထိုးငွေ {getMaxAmount()}
            </Text>
          </Col>
          <Col span={24}>
            <div
              onClick={() => {
                if (update || (update === 0 && updateAmount > 0)) {
                  const copy2d = select2D.map((value) => value);
                  copy2d[update] = {
                    ...copy2d[update],
                    amount: updateAmount,
                    is_select: true,
                  };
                  dispatch(updateInitial({ select2D: [...copy2d] }));

                  setUPdateAmount(false);
                  setUpdate(false);
                }
              }}
              style={{
                margin: "10px",
                background: "#34AF83",
                padding: "12px",
                textAlign: "center",
                borderRadius: "16px",
              }}
            >
              <Text strong style={{ fontSize: "1.2rem", fontWeight: "700" }}>
                အတည်ပြု
              </Text>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
