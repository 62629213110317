import { createSlice } from "@reduxjs/toolkit";

const originalFormat = {
  avatar: "",
  main: 0,
  promotion: 0,
  use_wallet: "promotion",
  user_id: "",
  agent_code: "",
  is_loading: true,
  language: "mm",
  window_height: window.innerHeight,
  twoDselectTime: 1,
  select_section: "",
  select_agent: false,
  select2D: [],
  select2Damount: 0,
  select_3d_agent: false,
  threeDselect: [],
  get3DList: [],
};

function addSession(data) {
  sessionStorage.setItem("ak_lottary_initial", JSON.stringify(data));
}

function removeSession() {
  sessionStorage.removeItem("ak_lottary_initial");
}

function getSession() {
  const initial = sessionStorage.getItem("ak_lottary_initial");
  if (initial) {
    return JSON.parse(initial);
  }
  return originalFormat;
}

const initialState = getSession();

export const initialSlice = createSlice({
  name: "initial",
  initialState,
  reducers: {
    updateInitial: (state, { payload }) => {
      state.user_id = payload.user_id || state.user_id;
      state.agent_code = payload.agent_code || state.agent_code;
      state.avatar = payload.avatar || state.avatar;
      state.main = payload.main === 0 ? 0 : payload.main || state.main;
      state.promotion = payload.promotion === 0 ? 0 : payload.promotion || state.promotion;
      state.use_wallet = payload.use_wallet || state.use_wallet;
      state.is_loading = payload.is_loading || false;
      state.language = payload.language || "mm";
      state.window_height = payload.window_height || state.window_height;
      state.twoDselectTime = payload.twoDselectTime || state.twoDselectTime;
      state.select2D = payload.select2D || state.select2D;
      state.select2Damount = payload.select2Damount || "";
      state.threeDselect = payload.threeDselect || state.threeDselect;
      state.get3DList = payload.get3DList || state.get3DList;
      state.select_section = payload.select_section || state.select_section;
      state.select_agent = payload.select_agent || state.select_agent;
      state.today_value = payload.today_value || state.today_value;
      state.select_3d_agent = payload.select_3d_agent || state.select_3d_agent;
      addSession(state);
    },
    clearInitial: () => {
      addSession(originalFormat);
    },
  },
});

export const { updateInitial, clearInitial } = initialSlice.actions;
export default initialSlice.reducer;
