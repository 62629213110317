import "./App.css";
import { HashRouter } from "react-router-dom";
import { ConfigProvider, Skeleton, message, theme } from "antd";
import LayoutBody from "./layout/layoutBody";
import React, { useEffect } from "react";
import { updateInitial } from "./store/user";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

function App() {
  const dispatch = useDispatch();
  const { is_loading } = useSelector((state) => state.initial);

  const params = new URLSearchParams(window.location.search);
  const initial = {
    agent_code: params.get("agent_code"),
    user_id: params.get("user_id"),
    language: params.get("language"),
    callback: params.get("callback"),
    window_height: window.innerHeight,
    is_loading: "false",
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/v1/agent/get_user_wallet/${initial.user_id}`
      )
      .then(({ data }) => {
        dispatch(
          updateInitial({
            ...initial,
            main: parseInt(data.main_wallet),
            promotion: parseInt(data.promotion_wallet),
            avatar: data.avatar,
            use_wallet: data.target_point,
          })
        );
      })
      .catch((error) => {
        message.error(error.message);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(updateInitial({ is_loading: false }));
    }, 1000);
  }, []);

  return (
    <HashRouter>
      <React.Suspense fallback={<Skeleton active />}>
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
            components: {
              Layout: {
                bodyBg: "#181F25",
              },
              Card: {
                colorBgContainer: "#1D262F",
              },
            },
          }}
        >
          <LayoutBody />
        </ConfigProvider>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
