import { Avatar, Col, Grid, Row, Tag, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LOCALE from "../lib/locale";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { clearInitial } from "../store/user";
const { useBreakpoint } = Grid;
const { Text } = Typography;

export default function WalletHeader({ title }) {
  const { avatar, main, promotion, language, callback, use_wallet } =
    useSelector((state) => state.initial);
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();

  function exit() {
    const params = new URLSearchParams(window.location.search);
    dispatch(clearInitial());

    window.parent.location.href = `${params.get("callback")}#/exit`;
  }

  useEffect(() => {
  }, []);

  function pageChangeHandler() {
    switch (true) {
      case location.pathname === "/":
        return exit();
        break;
      case location.pathname === "/twodcurrent":
        navigate("/twod");
        break;
      case location.pathname === "/twodnew":
        navigate("/twod");
        break;
      case location.pathname === "/twodselect":
        navigate("/twodnew");
        break;
      case location.pathname === "/twodlist":
        navigate("/twodselect");
        break;
      case location.pathname === "/threedcurrent":
        navigate("/threedhome");
        break;
      case location.pathname === "/threedselect":
        navigate("/threedhome");
        break;
      case location.pathname === "/threeDselectList":
        navigate("/threedselect");
        break;
      default:
        navigate("/");
        break;
    }
  }

  return (
    <Row
      justify={"space-between"}
      align={"middle"}
      style={{ padding: "4px 16px" }}
    >
      <Col>
        <img
          onClick={() => pageChangeHandler()}
          src={"/images/Left.png"}
          style={{ width: screens.lg ? "50px" : "40px" }}
          alt="logo"
        />
      </Col>
      {title ? (
        <Col flex={"auto"}>
          <Row justify={"center"}>
            <Col>
              <Text
                strong
                style={{
                  color: "#34AF83",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}
              >
                {title}
              </Text>
            </Col>
          </Row>
        </Col>
      ) : (
        <>
          <Col span={8}>
            <div
              style={
                use_wallet !== "main_wallet" ? { opacity: "0.5" } : { opacity: "1" }
              }
            >
              <Row
                gutter={8}
                align={"middle"}
                style={{
                  background: "#2A3642",
                  border:
                    use_wallet !== "promotion_wallet" ? "1px solid #6b76b6" : "none",
                  borderRadius: "12px",
                  padding: "4px 6px 4px 4px",
                }}
              >
                <Col span={6} style={{ height: "22px" }}>
                  <img
                    src={"/images/coin.png"}
                    alt="coin"
                    style={{ height: "22px" }}
                  />
                </Col>{" "}
                <Col span={18} style={{ height: "max-content" }}>
                  <Row style={{ padding: 0, margin: 0, height: "max-content" }}>
                    <Col span={24}>
                      <Text style={{ lineHeight: "10px" }} strong>
                        {main}
                      </Text>
                    </Col>
                    <Col
                      span={24}
                      style={{ height: "0.7rem", lineHeight: "0.5rem" }}
                    >
                      <Tag
                        bordered={false}
                        style={{
                          backgroundColor: "#2A3642",
                          padding: 0,
                          fontSize: "0.5rem",
                          lineHeight: "0.5rem",
                        }}
                      >
                        {LOCALE.components.walletHeader.point[language]}
                      </Tag>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          {/* <Col span={8}>
            <div
              style={
                use_wallet !== "promotion_wallet"
                  ? { opacity: "0.5" }
                  : { opacity: "1" }
              }
            >
              <Row
                gutter={8}
                align={"middle"}
                style={{
                  background: "#2A3642",
                  border:
                    use_wallet === "promotion_wallet" ? "1px solid #6b76b6" : "none",
                  borderRadius: "12px",
                  padding: "4px 6px 4px 4px",
                }}
              >
                <Col span={6} style={{ height: "22px" }}>
                  <img
                    src={"/images/promo.png"}
                    alt="coin"
                    style={{ height: "22px" }}
                  />
                </Col>
                <Col span={18} style={{ height: "max-content" }}>
                  <Row style={{ padding: 0, margin: 0, height: "max-content" }}>
                    <Col span={24}>
                      <Text style={{ lineHeight: "10px" }} strong>
                        {promotion}
                      </Text>
                    </Col>
                    <Col
                      span={24}
                      style={{ height: "0.7rem", lineHeight: "0.5rem" }}
                    >
                      <Tag
                        bordered={false}
                        style={{
                          backgroundColor: "#2A3642",
                          padding: 0,
                          fontSize: "0.5rem",
                          lineHeight: "0.5rem",
                        }}
                      >
                        {
                          LOCALE.components.walletHeader.promotionPoint[
                            language
                          ]
                        }
                      </Tag>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col> */}
          <Col>
            <Avatar
              style={{ border: "1px solid #5B5B5B" }}
              size={40}
              src={avatar}
            />
          </Col>
        </>
      )}
    </Row>
  );
}
