import { Route, Routes } from "react-router";
import HomePage from "../pages/home";
import TwoDHome from "../pages/twoDHoome";
import TwoDCurrent from "../pages/twodCurrent";
import TwoDNew from "../pages/twodNew";
import TwoDSelect from "../pages/twoDSelect";
import TwoDSelectedList from "../pages/twoDselectedList";
import ThreeDHome from "../pages/threeDHome";
import ThreeDCurrent from "../pages/threeDcurrent";
import ThreeDSelect from "../pages/threeDselect";
import ThreeDSelectList from "../pages/threeDselectList";

export default function LayoutBody() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/twod" element={<TwoDHome />} />
      <Route path="/twodcurrent" element={<TwoDCurrent />} />
      <Route path="/twodnew" element={<TwoDNew />} />
      <Route path="/twodselect" element={<TwoDSelect />} />
      <Route path="/twodlist" element={<TwoDSelectedList />} />
      <Route path="/threedhome" element={<ThreeDHome />} />
      <Route path="/threedcurrent" element={<ThreeDCurrent />} />
      <Route path="/threedselect" element={<ThreeDSelect />} />
      <Route path="/threeDselectList" element={<ThreeDSelectList />} />

      <Route path="*" element={<HomePage />} />
    </Routes>
  );
}
