import { Col, Layout, Row, Spin, Tag, Typography, message } from "antd";
import { useSelector } from "react-redux";
import WalletHeader from "../component/walletHeader";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";
const { Text } = Typography;

export default function ThreeDCurrent() {
  const { window_height, user_id, agent_code } = useSelector(
    (state) => state.initial
  );
  const [loading, setLoading] = useState(false);
  const [todayValue, setTodayValue] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios.get(
      `${process.env.REACT_APP_SERVER_API}/v1/bet/three_user_oingong/${user_id}`
    ).then(({ data }) => {
        setTodayValue(
          data.map((twod) => ({
            number: twod.bit_number,
            time: dayjs(twod.threed_ongoing.threed_round.target_date).format(
              "DD-MM-YYYY"
            ),
            id: twod.id,
            amount: twod.bit_amount,
          }))
        );
      })
      .catch((error) => message.error(error.message))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div style={{ height: window_height, width: "100vw", overflow: "hidden" }}>
      <Layout>
        <Layout.Content>
          <WalletHeader title={"ထီထိုးစာရင်း"} />
          <div
            style={{
              height: `${window_height - 60}px`,
              overflowY: "auto",
              overflowX: "hidden",
              background: "#1D262F",
              scrollbarWidth: "none",
            }}
          >
            <Row
              style={{
                padding: "16px",
              }}
            >
              <Col span={24}>
                <Spin spinning={loading}>
                  <Row justify={"center"}>
                    <Col
                            style={{
                              background: "#1D262F",
                              padding: "8px 12px",
                              borderRadius: "8px",
                            }}
                            span={24}
                          >
                            <Row justify={"space-between"} align={"middle"}>
                              <Col span={10}>
                                <Row>
                                    <Col
                                      span={24}
                                      style={{ paddingBottom: "12px" }}
                                    >
                                      <Text
                                        style={{ marginLeft: "20px" }}
                                        strong
                                      >
                                        အချိန်
                                      </Text>
                                    </Col>
                                </Row>
                              </Col>
                              <Col span={4}>
                                <Row justify={"center"}>
                                    <Col style={{ paddingBottom: "12px" }}>
                                      <Text strong>ဂဏန်း</Text>
                                    </Col>
                                    </Row>
                              </Col>
                              <Col span={6}>
                                <Row justify={"end"}>
                                    <Col style={{ paddingBottom: "12px" }}>
                                      <Text
                                        style={{ marginRight: "16px" }}
                                        strong
                                      >
                                        ပမာဏ
                                      </Text>
                                    </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                    {todayValue.length > 0 && (
                      <>
                        {todayValue.map((timeNumber, index) => (
                          <Col
                            style={{
                              background: index % 2 ? "#2A3642" : "#1D262F",
                              padding: "8px 12px",
                              borderRadius: "8px",
                            }}
                            span={24}
                            key={timeNumber.id}
                          >
                            <Row justify={"space-between"} align={"middle"}>
                              <Col span={10}>
                                <Row>
                                  <Col>
                                    <Text
                                      style={{
                                        fontSize: "1.2rem",
                                      }}
                                      strong
                                    >
                                      {timeNumber.time}
                                    </Text>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={4}>
                                <Row justify={"center"}>
                                  <Col>
                                    <Text
                                      style={{
                                        fontSize: "1.4rem",
                                        color: "#34AF83",
                                      }}
                                      strong
                                    >
                                      {timeNumber.number}
                                    </Text>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={6}>
                                <Row justify={"end"}>
                                  <Col>
                                    <Tag
                                      bordered={false}
                                      style={{
                                        width: "60px",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                        letterSpacing: "1px",
                                        fontWeight: "700",
                                        background: "#181F25",
                                      }}
                                    >
                                      {timeNumber.amount}
                                    </Tag>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </>
                    )}
                  </Row>
                </Spin>
              </Col>
              <Col span={24} style={{ height: "100vh" }}></Col>
            </Row>
          </div>
        </Layout.Content>
      </Layout>
    </div>
  );
}
