import { Card, Col, ConfigProvider, Row, Typography } from "antd";
import { useNavigate } from "react-router";
const { Text } = Typography;

export default function TwoDCard() {
  const navigate = useNavigate();

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            colorBgContainer: "#2A3642",
          },
        },
      }}
    >
      <Card
        onClick={() => navigate('/twod')}
        bordered={false}
        style={{ borderRadius: "15px" }}
        styles={{ body: { padding: "8px" } }}
      >
        <Row justify={"center"}>
          <Col span={24}>
            <img style={{ width: "100%" }} src="/games/2d3d.png" alt="ball" />
          </Col>
          <Col>
            <Text strong>Lucky Lotto (2D)</Text>
          </Col>
          <Col>
            <Row align={"middle"}>
              <Col style={{ paddingTop: "4px" }}>
                <img
                  src={"/games/ak.png"}
                  style={{ height: "12px" }}
                  alt="ak"
                />
              </Col>
              <Col>
                <Text style={{ fontSize: "0.6rem", color: "#34AF83" }}>
                  AK team
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </ConfigProvider>
  );
}
