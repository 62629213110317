const LOCALE = {
  loginPage: {
    login: {
      title: { en: "Login", mm: "အကောင့်ဝင်ရန်" },
      name: { en: "Username", mm: "အမည်ရေးရန်" },
      password: { en: "Password", mm: "လျှို့ဝှက်နံပါတ်ရေးရန်" },
      submit: { en: "Login", mm: "အတည်ပြု" },
      signUp: { en: "Sing Up", mm: "အကောင့်သစ်ဖွင့်ရန်နှိပ်ပါ" },
    },
    signUp: {
      title: { en: "Sign Up", mm: "အကောင့်သစ်ဖွင့်ရန်" },
      name: { en: "Username", mm: "အမည်ရေးရန်" },
      password: { en: "Password", mm: "လျှို့ဝှက်နံပါတ်ရေးရန်" },
      confirmPassword: {
        en: "Confirm Password",
        mm: "လျှို့ဝှက်နံပါတ်အသစ်ပြန်ရေးရန်",
      },
      submit: { en: "Create", mm: "အတည်ပြု" },
      login: { en: "Login", mm: "အကောင့်ဝင်ရန်နှိပ်ပါ" },
    },
  },
  walletPage: {
    notice: {
      title: { mm: "အသိပေးချက်", en: "Important Notice" },
      text1: {
        mm: "ငွေထုတ်ရန်အတွက်",
        en: "To process a withdrawal, you will need to ensure there's a minimum of",
      },
      text2: { mm: "အတွင်းလက်ကျန်", en: "in your" },
      text3: { mm: "ကျန်ရှိရန်လိုအပ်ပါသည်။", en: "." },
    },
    deposit: { mm: "ငွေသွင်း", en: "Top Up" },
    withdraw: { mm: "ငွေထုတ်", en: "Withdraw" },
  },
  depositPage: {
    title: { mm: "ငွေသွင်းရန်", en: "Top Up" },
    accountName: { mm: "အကောင့်နာမည်", en: "Account Name" },
    phoneNo: { mm: "ဖုန်းနံပါတ်", en: "Phone Number" },
    upload: {
      mm: "ငွေဖြတ်ပြေစာထည့်ရန်နှိပ်ပါ",
      en: "Drop your E-Receipt here",
    },
    pomotionText: {
      mm: "ပရိုမိုးရှင်း အမှတ်များရယူမည်",
      en: "Claim your promotion coins",
    },
    amount: { mm: "ငွေပမာဏရေးရန်", en: "Enter the amount" },
    submit: { mm: "အတည်ပြုမည်", en: "Submit" },
    promotionNoticTitle: {mm:"ပရိုမိုးရှင်း အမှတ်များ",en:"Promo Points"},
    promotionNoticText: {mm:"ပရိုမိုးရှင်းအမှတ်များကို ထီများနှင့် ဂိမ်းများတွင်သာမက ငွေထုတ်ခြင်းအတွက်ပါ အသုံးပြုနိုင်ပါသည်",en:"Promotion points can be used not only for participating in lotteries and games but also for withdrawals."},
    accept: {mm:"အတည်ပြု", en: "Accept" },
    cancel: {mm:"ပယ်ဖျက်", en: "Cancel" },
  },
  components: {
    walletHeader: {
      point: { mm: "ပင်မ ပိုက်ဆံအိတ်", en: "Main Wallet" },
      promotionPoint: { mm: "Promo ပိုက်ဆံအိတ်", en: "Promotion Wallet" },
    },
    menuFooter: {
      home: { mm: "ပင်မ", en: "Home" },
      wallet: { mm: "ပိုက်ဆံအိတ်", en: "Wallet" },
      log: { mm: "မှတ်တမ်း", en: "Record" },
    },
    famousSection: {
      title1: { mm: "လူကြိုက်များသော", en: "Popular" },
      title2: { mm: "ဂိမ်းများ", en: "Games" },
    },
    allGamesSection: {
      title1: { mm: "ဂိမ်းများ", en: "All" },
      title2: { mm: "အားလုံး", en: "Games" },
    },
  },
};

export default LOCALE;
